import {
  useContext,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "rsuite";
import Forbidden from "../components/forbidden";
import SidebarNav from "../components/sidebar";
import { GetProfileResponseData } from "../grpc/service/general_pb";
import { SidebarContext } from "../objects/SidebarContext";
import { REFRESH_TOKEN, TOKEN, USER_ROLE } from "../utils/contants";
import { asyncLocalStorage } from "../utils/helper";
import ProfileMid from "./ProfileMid";
import { RiLogoutCircleRLine } from "react-icons/ri";

interface AdminMidProps {
  children: ReactNode;
  onLoadProfile?: (d: GetProfileResponseData.AsObject) => void;
  noPadding?: boolean;
  hideHeader?: boolean;
  hideMenu?: boolean;
  header?: ReactNode;
}

const AdminMid: FC<AdminMidProps> = ({
  children,
  onLoadProfile,
  noPadding,
  hideHeader,
  hideMenu,
  header,
}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [role, setRole] = useState("");
  const nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const location = useLocation();
  const { isOpen, setOpen } = useContext(SidebarContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    asyncLocalStorage.getItem(USER_ROLE).then((v) => setRole(v));
  }, [mounted]);

  useEffect(() => {
    // if (profile)
    // console.log(profile)
  }, [profile]);

  const logout = async () => {
    await asyncLocalStorage.removeItem(TOKEN);
    await asyncLocalStorage.removeItem(REFRESH_TOKEN);
    window.location.href = "/login";
  };
  return role != "admin" ? (
    <Forbidden />
  ) : (
    <ProfileMid
      onLoad={(val) => {
        setProfile(val);
        if (onLoadProfile) onLoadProfile(val);
      }}
    >
      <div className="flex flex-row w-screen h-full">
        <div className=" bg-red-100">
          <SidebarNav />
        </div>
        <div className="flex-grow h-full flex  flex-col">
          <div className="w-full bg-white border-b h-[60px] flex justify-between flex-row items-center p-4">
            <HiOutlineMenuAlt2
              size={24}
              className="cursor-pointer"
              onClick={() => setOpen(!isOpen)}
            />
            <div className="flex flex-row gap-2 justify-center items-center">
              <div
                className="flex justify-center items-center gap-2 cursor-pointer"
                onClick={() => nav("/profile")}
              >
                <Avatar src={profile?.profilePictureUrl} circle size="sm" />
                <span className="font-semibold">{profile?.name}</span>
              </div>
              <RiLogoutCircleRLine size={24} className="cursor-pointer" onClick={logout} />
            </div>
          </div>
          <div className={`flex-1 ${!noPadding && "p-4"} overflow-y-auto `}>
            {children}
          </div>
        </div>
      </div>

      {/* {!hideMenu &&
            <div className="flex  w-full  menu-bottom justify-between bg-white shadow-[_0_0px_10px_rgba(0,0,0,0.1)] text-gray-400 px-4 ">
                <Link to={'/admin'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin' && 'active'}`} >
                    {location.pathname == '/admin' ? <HiHome size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineHome size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Home</span>
                </Link>
                <Link to={'/admin/client'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/client' && 'active'}`} >
                    {location.pathname == '/admin/client' ? <HiUsers size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineUsers size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Client</span>
                </Link>
                <Link to={'/admin/affiliator'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/affiliator' && 'active'}`} >
                    {location.pathname == '/admin/affiliator' ? <HiUserGroup size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineUserGroup size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Affiliator</span>
                </Link>

                <Link to={'/admin/package'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/package' && 'active'}`} >
                    {location.pathname == '/admin/package' ? <HiShoppingBag size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineShoppingBag size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Order</span>
                </Link>
                <Link to={'/admin/profile'} className={`flex cursor-pointer w-36 flex-col hover:text-sm transition-all text-center justify-center items-center mx-2 py-2 ${location.pathname == '/admin/profile' && 'active'}`} >
                    {location.pathname == '/admin/profile' ? <HiBriefcase size={24} className='w-6 cursor-pointer transition' /> : <HiOutlineBriefcase size={24} className='w-6 cursor-pointer transition' />}
                    <span className='text-xxs hover:text-xs'>Profil</span>
                </Link>
            </div>
        } */}
    </ProfileMid>
  );
};
export default AdminMid;
