import { useContext, useEffect, useState, type FC } from "react";
import {
  HiFunnel,
  HiMagnifyingGlass,
  HiOutlineFunnel,
  HiXMark,
} from "react-icons/hi2";
import { LoadingContext } from "../../objects/loading_context";
import { adminClientClient } from "../../App";
import { getToken, money } from "../../utils/helper";
import {
  GetClientListRequest,
  GetClientListResponse,
  GetClientListResponseData,
  Pagination,
} from "../../grpc/admin/admin_client_pb";
import { errorToast } from "../../utils/helper-ui";
import {
  MARKETER,
  SORT_CREATED_AT,
  SORT_NAME,
  SORT_PAYMENT,
} from "../../utils/contants";
import NoData from "../no_data";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import Moment from "react-moment";
import { Drawer } from "rsuite";
import CustomTable from "../custom_table";
import { BsEye } from "react-icons/bs";

interface AdminClientListProps {
  mode: string;
  onClickHistory?: (user: GetClientListResponseData.AsObject) => void;
}

const AdminClientList: FC<AdminClientListProps> = ({
  mode,
  onClickHistory,
}) => {
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const [search, setSearch] = useState("");
  const [data, setData] = useState<GetClientListResponseData.AsObject[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState(SORT_CREATED_AT);
  const [sort, setSort] = useState("DESC");
  const [modalFilter, setModalFilter] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
  }, [mounted]);

  useEffect(() => {
    if (mounted) getData();
  }, [mounted, page, limit, sortBy, sort, search]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp: GetClientListResponseData.AsObject[] = await new Promise(
        (resolve, reject) => {
          let req = new GetClientListRequest();
          req.setPage(page);
          req.setLimit(limit);
          req.setSortBy(sortBy);
          req.setSort(sort);
          req.setName(search);
          
          if (mode == MARKETER) {
            req.setIsMarketing(true);
          } else {
            req.setStatus(mode);
          }
          adminClientClient.getClientList(req, getToken(), (err, resp) => {
            if (err) {
              reject(err.message);
              return;
            }
            setPagination(resp.getPagination()?.toObject() ?? null);
            resolve(resp.getDataList().map((e) => e.toObject()));
          });
        }
      );
      setData(resp);
      // setOrders([...orders, ...resp])
      // console.log(resp)
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderTable = () => (
    <div className="mt-4">
      <CustomTable
        pagination={true}
        total={pagination?.totalRecords}
        limit={limit}
        activePage={page}
        setActivePage={(val) => setPage(val)}
        changeLimit={(val) => setLimit(val)}
        onSearch={(val) => setSearch(val)}
        switchHeader={true}
        searchHeader={
          <div
            className=" border-gray-300 rounded-lg p-2.5 ml-2  border-2"
            onClick={() => setModalFilter(true)}
          >
            <HiOutlineFunnel />
          </div>
        }
        headers={[
          "ID",
          "Nama",
          "Telp",
          "Pembayaran",
          "Tgl Berakhir",
          "Affiliator",
          "",
        ]}
        headerClasses={["w-5"]}
        datasets={data.map((e) => ({
          cells: [
            { data: e.id },
            { data: e.name },
            { data: e.phoneNumber },

            {
              data:
                e.userPackageTotalPrice > 0
                  ? money(e.userPackageTotalPrice)
                  : "-",
            },
            {
              data: e.userPackageExpiryDate && (
                <Moment format="DD/MM/YYYY">{e.userPackageExpiryDate}</Moment>
              ),
            },
            { data: e.userPackageAffiliatorName },
            {
              data: (
                <BsEye
                  className=" text-blue-400 cursor-pointer"
                  size={16}
                  onClick={() => {
                    if (onClickHistory) onClickHistory(e);
                  }}
                />
              ),
            },
          ],
        }))}
      />
    </div>
  );
  const renderList = () => (
    <div className="mt-4">
      <div className="flex">
        <div className="w-1/2 font-bold">Nama</div>
        <div className="w-1/2 font-bold">Payment</div>
        <div></div>
      </div>
      <ul>
        {data.map((e) => (
          <li className="" key={e.id}>
            <div className="flex p-4  bg-sky-50 mx-1 my-2 rounded-lg cursor-pointer">
              <div className="w-1/2">{e.name}</div>
              <div
                className="w-1/2 flex justify-between"
                onClick={() => {
                  if (expandedIds.includes(e.id)) {
                    setExpandedIds([...expandedIds.filter((id) => !e.id)]);
                  } else {
                    setExpandedIds([...expandedIds, e.id]);
                  }
                }}
              >
                {e.userPackageTotalPrice > 0
                  ? money(e.userPackageTotalPrice)
                  : "-"}
                {expandedIds.includes(e.id) ? (
                  <RiArrowUpSFill size={24} className=" text-sky-600 " />
                ) : (
                  <RiArrowDownSFill size={24} className=" text-sky-600 " />
                )}
              </div>
            </div>
            {expandedIds.includes(e.id) && (
              <div className="flex flex-col p-4  bg-gray-50 mx-1 my-2 rounded-lg cursor-pointer">
                <div className="flex mb-2">
                  <div className="w-1/2">
                    <p className="font-semibold">ID</p>
                  </div>
                  <div className="w-1/2 text-right">
                    <p>{e.id}</p>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="w-1/2">
                    <p className="font-semibold">No. Telp</p>
                  </div>
                  <div className="w-1/2 text-right">
                    <p>{e.phoneNumber}</p>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="w-1/2">
                    <p className="font-semibold">Affiliator</p>
                  </div>
                  <div className="w-1/2 text-right">
                    <p>{e.userPackageAffiliatorName}</p>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="w-1/2">
                    <p className="font-semibold">Tgl. Berakhir</p>
                  </div>
                  <div className="w-1/2 text-right">
                    {e.userPackageExpiryDate && (
                      <Moment format="DD/MM/YYYY">
                        {e.userPackageExpiryDate}
                      </Moment>
                    )}
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="w-1/2">
                    <p className="font-semibold">History</p>
                  </div>
                  <div className="w-1/2 text-right">
                    <p
                      className="font-semibold text-sky-600"
                      onClick={() => {
                        if (onClickHistory) onClickHistory(e);
                      }}
                    >
                      Lihat
                    </p>
                  </div>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
  return (
    <div className="p-4">
      {/* <div className="flex">
        <div className="relative flex-1">
          <input className="search-control" placeholder="Search ...." />
          <HiMagnifyingGlass className="absolute top-2.5 left-4 text-lg text-gray-400" />
        </div>
        <div
          className=" border-gray-300 rounded-lg p-2.5 ml-2  border-2"
          onClick={() => setModalFilter(true)}
        >
          <HiOutlineFunnel />
        </div>
      </div> */}
      <div>{renderTable()}</div>
      {/* <div className=" justify-center items-start flex">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div> */}
      <Drawer
        className="drawer-rounded"
        size={"xs"}
        closeButton={null}
        placement={"right"}
        open={modalFilter}
        onClose={() => setModalFilter(false)}
      >
        <Drawer.Title>
          <div className="flex p-4  justify-between items-center">
            <div className="flex   items-center">
              <p> Filter</p>
            </div>
            <HiXMark onClick={() => setModalFilter(false)} />
          </div>
        </Drawer.Title>
        <Drawer.Body style={{ padding: 20 }}>
          <div className="mb-4">
            <p className="font-semibold mb-2">Urutkan</p>
            <div className=" flex">
              <div
                onClick={() => {
                  setData([]);
                  setSortBy(SORT_NAME);
                  setSort("ASC");
                }}
                className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                  sortBy == SORT_NAME &&
                  sort == "ASC" &&
                  "bg-sky-400 text-white"
                }`}
              >
                {" "}
                A - Z
              </div>
              <div
                onClick={() => {
                  setData([]);
                  setSortBy(SORT_NAME);
                  setSort("DESC");
                }}
                className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                  sortBy == SORT_NAME &&
                  sort == "DESC" &&
                  "bg-sky-400 text-white"
                }`}
              >
                {" "}
                Z - A
              </div>
            </div>
          </div>
          <div className="mb-4">
            <p className="font-semibold mb-2">Waktu Pesan</p>
            <div className=" flex">
              <div
                onClick={() => {
                  setData([]);
                  setSortBy(SORT_CREATED_AT);
                  setSort("DESC");
                }}
                className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                  sortBy == SORT_CREATED_AT &&
                  sort == "DESC" &&
                  "bg-sky-400 text-white"
                }`}
              >
                {" "}
                Paling Baru
              </div>
              <div
                onClick={() => {
                  setData([]);
                  setSortBy(SORT_CREATED_AT);
                  setSort("ASC");
                }}
                className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                  sortBy == SORT_CREATED_AT &&
                  sort == "ASC" &&
                  "bg-sky-400 text-white"
                }`}
              >
                {" "}
                Paling Lama
              </div>
            </div>
          </div>
          <div className="mb-4">
            <p className="font-semibold mb-2">Payment</p>
            <div className=" flex">
              <div
                onClick={() => {
                  setData([]);
                  setSortBy(SORT_PAYMENT);
                  setSort("DESC");
                }}
                className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                  sortBy == SORT_PAYMENT &&
                  sort == "DESC" &&
                  "bg-sky-400 text-white"
                }`}
              >
                {" "}
                Paling Besar
              </div>
              <div
                onClick={() => {
                  setData([]);
                  setSortBy(SORT_PAYMENT);
                  setSort("ASC");
                }}
                className={`border rounded-full py-2 px-4 min-w-[100px] text-center mr-2 ${
                  sortBy == SORT_PAYMENT &&
                  sort == "ASC" &&
                  "bg-sky-400 text-white"
                }`}
              >
                {" "}
                Paling Kecil
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
};
export default AdminClientList;
