import { useContext, useEffect, useState, type FC } from 'react';
import { LoadingContext } from '../../objects/loading_context';
import { adminOrderClient } from '../../App';
import { getToken, numberToDuration } from '../../utils/helper';
import { errorToast } from '../../utils/helper-ui';
import { GetOrderListRequest, GetOrderListResponseData, Pagination } from '../../grpc/admin/admin_order_pb';
import moment from 'moment';
import CommentIcon from "../../icon_svg/comment.svg"
import { useNavigate } from 'react-router-dom';
interface AdminListCommentOrderProps { }

const AdminListCommentOrder: FC<AdminListCommentOrderProps> = ({ }) => {
    const nav = useNavigate()
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [orders, setOrders] = useState<GetOrderListResponseData.AsObject[]>([]);
    const [pagination, setPagination] = useState<Pagination.AsObject | null>(null);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [mounted, setMounted] = useState(false);


    useEffect(() => {
        setMounted(mounted)
    }, []);

    useEffect(() => {
        getOrder()
    }, [mounted, page, limit]);

    const getOrder = async () => {
        try {
            setIsLoading(true)
            let resp: GetOrderListResponseData.AsObject[] = await new Promise((resolve, reject) => {
                let req = new GetOrderListRequest()
                req.setPage(page)
                req.setLimit(limit)
                adminOrderClient.getOrderList(req, getToken(), (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }
                    setPagination(resp.getPagination()?.toObject() ?? null)
                    resolve(resp.getDataList().map(e => e.toObject()))
                })
            })
            setOrders([...orders, ...resp])
            // console.log(resp)
        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <div className=' bg-white p-4  rounded-xl shadow-sm hover:shadow-lg'>
            <h5 className='mb-4'>Warning</h5>
            {orders.map(e => {
                let hours = moment.utc(e.dueDate).diff(moment.utc(), 'hours', false)
                let gradient = ""
                if (hours < 4) {
                    gradient = "from-yellow-100 via-white to-white bg-gradient-to-b"
                }
                if (hours < 0) {
                    gradient = "from-red-200 via-white to-white bg-gradient-to-b"

                }

                return <div className={`p-4 rounded-lg bg-white shadow-md shadow-gray-100 last:mb-0 mb-4 ${gradient}`} key={e.id}>
                    <div className='flex justify-between mb-4'>
                        <div className='flex'>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/logo.svg";
                            }} src={e.user?.profilePictureUrl} alt="" className=" object-cover w-8 h-8 rounded-lg bg-gray-50 mr-2" />
                            <span className=' font-semibold'>{e.user?.name}</span>
                        </div>
                        {numberToDuration(moment.utc().diff(moment.utc(e.dueDate), 'minutes'))}
                    </div>
                    <div className='flex'>
                        <img onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/logo.svg";
                        }} src={e.thumbnailUrl} alt="" className=" object-cover w-16 h-16 rounded-lg bg-gray-50 mr-2 flex" />
                        <div className='flex flex-col '>
                            <h6 className='overflow-hidden w-64 truncate'> {e.title} </h6>
                            <p className='overflow-hidden w-64 truncate'>
                                {e.description}
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center'>
                        <div className='relative'>
                            <img src={CommentIcon} alt="" className='w-6 text-gray-200' />
                            {e?.hasUnreadMessage && <div className='w-1.5 h-1.5 absolute top-0 right-0 bg-orange-400 rounded-full'></div>}
                        </div>
                        <button className='btn-white' onClick={() => {
                            nav(`/admin/order/${e.id}`)
                        }}>Lihat</button>
                    </div>

                </div>;
            })}
            <div className=' justify-center items-start flex'>
                {(pagination?.totalPages ?? 0) > 0 && (pagination?.next ?? 0) > page && <button className='btn-white w-48' onClick={() => {
                    setPage(page + 1)
                }}>More</button>}

            </div>
        </div>);
}
export default AdminListCommentOrder;