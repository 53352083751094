import { useContext, useEffect, useState, type FC } from "react";
import { LoadingContext } from "../../objects/loading_context";
import { adminOrderClient, adminUserPackageClient } from "../../App";
import { getToken, initials, money } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import {
  GetListUserPackageRequest,
  GetListUserPackageResponseData,
  Pagination,
} from "../../grpc/admin/admin_user_package_pb";
import {
  FAILED,
  ORDER_AFFILIATOR,
  ORDER_COMMENTATOR,
  ORDER_CONFIRMATION,
  ORDER_DONE,
  ORDER_FAILED,
  PAID,
  PENDING_AFFILIATOR,
  PENDING_COMMENTATOR,
  PENDING_CONFIRMATION,
} from "../../utils/contants";
import Moment from "react-moment";
import { Avatar } from "rsuite";
import LoadingComponent from "../loading_component";
import NoData from "../no_data";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomTable from "../custom_table";

interface AdminOrderListProps {
  mode: string;
  isTable?: boolean;
}

const AdminOrderList: FC<AdminOrderListProps> = ({ mode, isTable }) => {
  let nav = useNavigate();
  const [mounted, setMounted] = useState(false);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  // const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState<Pagination.AsObject | null>(
    null
  );
  const [data, setData] = useState<GetListUserPackageResponseData.AsObject[]>(
    []
  );
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [modalFilter, setModalFilter] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
  }, [mounted]);

  useEffect(() => {
    if (mounted) getData();
  }, [mounted, page, limit]);

  const renderCard = (e: GetListUserPackageResponseData.AsObject) => (
    <div className=" bg-white mb-2">
      <div className="flex justify-between py-4 px-4 items-center border-b border-b-gray-200">
        <div className=" flex items-center">
          <Avatar
            size={"sm"}
            circle
            src={e.user?.profilePictureUrl}
            alt={
              e.user?.profilePictureUrl ? e.user.name : initials(e.user?.name)
            }
          />
          <p className=" font-semibold ml-2">{e.user?.name}</p>
        </div>
        {/* {mode == ORDER_UNPAID && <span className='text-sm text-red-500'>Belum dibayar</span>}
                {mode == ORDER_PROCESSING && <span className='text-sm text-sky-500'>Sedang diproses</span>}
                {mode == ORDER_DONE && <span className='text-sm text-sky-500'>Berhasil</span>}
                {mode == ORDER_FAILED && <span className='text-sm text-red-500'>Gagal</span>} */}
      </div>
      <div className="p-4 grid grid-cols-5 gap-4 border-b border-b-gray-200">
        <div className="flex flex-col">
          <div className=" bg-sky-50 flex justify-center items-center rounded-lg p-2">
            <img
              src={
                e.packageName.toLocaleLowerCase() == "premium"
                  ? `/assets/business_logo.png`
                  : `/assets/freemium_logo.png`
              }
              alt=""
              className="w-10 h-10"
            />
          </div>
        </div>
        <div className=" col-span-4">
          <h6>{e.packageName}</h6>
          <div className="flex justify-between text-sm">
            <span className="">Total Komentar</span>
            <span>{money(e.commentPerDay)} / Hari</span>
          </div>
          <div className="flex justify-between">
            <span className="">Total Sebulan</span>
            <span>{money(e.totalComment)}</span>
          </div>
          <div className="flex justify-between">
            <span className=" text-sky-500">+ Bonus Likes</span>
            <span className="text-sky-500">{money(e.totalLikes)}</span>
          </div>
          <div className="flex justify-between">
            <span className=" text-sky-500">+ Bonus Shares</span>
            <span className="text-sky-500">{money(e.totalShares)}</span>
          </div>
        </div>
      </div>
      {e?.totalPrice ? (
        <div className="p-4 justify-end flex border-b border-b-gray-200 items-center ">
          Total Pesanan{" "}
          <span className="font-semibold ml-2">
            {" "}
            Rp. {money(e?.totalPrice).split("").slice(0, -3)}
            <span className=" text-orange-400">
              {money(e?.totalPrice)
                .split("")
                .slice(Math.max(money(e?.totalPrice).split("").length - 3, 1))}
            </span>
          </span>
        </div>
      ) : null}
      <div className="p-4 justify-between flex border-b border-b-gray-200 items-center ">
        <div className="">
          {mode == ORDER_CONFIRMATION && (
            <div>
              {" "}
              Mohon Konfirmasi Sebelum{" "}
              <Moment format="DD MMMM YYYY">{e.confirmationExpiryTime}</Moment>
            </div>
          )}
          {mode == ORDER_AFFILIATOR && !e.affiliator?.name && (
            <div>
              Menunggu persetujuan Affiliator
              <br />
              <Moment format="DD MMMM YYYY">
                {moment.utc(e.createdAt).add(1, "days")}
              </Moment>
            </div>
          )}
          {mode == ORDER_COMMENTATOR &&
            e.commentatorAccountSlot != e.commentatorAccountRemainingSlot && (
              <div>
                Menunggu persetujuan Komentator
                <br />
                <Moment format="DD MMMM YYYY">
                  {moment.utc(e.createdAt).add(1, "days")}
                </Moment>
              </div>
            )}
          {mode == ORDER_DONE && (
            <div>
              Berhasil Dikonfirmasi{" "}
              {e.confirmedAt && (
                <Moment format="DD MMMM YYYY">
                  {moment.utc(e.confirmedAt)}
                </Moment>
              )}{" "}
            </div>
          )}
          {mode == ORDER_FAILED && `Konfirmasi Ditolak`}
        </div>

        <div className="ml-4">
          <button
            onClick={() => {
              nav(`/admin/package/${e.id}?status=${mode}`);
            }}
            type="button"
            className="btn"
          >
            Lihat Detail
          </button>
        </div>
      </div>
    </div>
  );

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp: GetListUserPackageResponseData.AsObject[] = await new Promise(
        (resolve, reject) => {
          let req = new GetListUserPackageRequest();
          req.setPage(page);
          req.setLimit(limit);
          req.setShowUserName(true);
          switch (mode) {
            case ORDER_CONFIRMATION:
              req.setPaymentStatus(PENDING_CONFIRMATION);
              break;
            case ORDER_AFFILIATOR:
              req.setPaymentStatus(PENDING_AFFILIATOR);
              break;
            case ORDER_COMMENTATOR:
              req.setPaymentStatus(PENDING_COMMENTATOR);
              break;
            case ORDER_DONE:
              req.setPaymentStatus(PAID);
              break;
            default:
              req.setPaymentStatus(FAILED);
              break;
          }

          adminUserPackageClient.getListUserPackage(
            req,
            getToken(),
            (err, resp) => {
              if (err) {
                reject(err.message);
                return;
              }
              setPagination(resp.getPagination()?.toObject() ?? null);
              resolve(resp.getDataList().map((e) => e.toObject()));
            }
          );
        }
      );
      setData(resp);
    } catch (error) {
      errorToast(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (isTable) {
    return (
      <div className="p-4 bg-white w-full h-full">
        <CustomTable
          headers={["No", "Nama", "Paket", "Total", ""]}
          headerClasses={["w-4", "w-60", "", "", "w-24"]}
          datasets={data.map((e) => ({
            cells: [
              { data: data.indexOf(e) + 1 },
              {
                data: (
                  <div className=" flex items-center">
                    <Avatar
                      size={"sm"}
                      circle
                      src={e.user?.profilePictureUrl}
                      alt={
                        e.user?.profilePictureUrl
                          ? e.user.name
                          : initials(e.user?.name)
                      }
                    />
                    <p className=" font-semibold ml-2">{e.user?.name}</p>
                  </div>
                ),
              },
              {
                data: (
                  <>
                    <div>
                      <img
                        src={
                          e.packageName.toLocaleLowerCase() == "premium"
                            ? `/assets/business_logo.png`
                            : `/assets/freemium_logo.png`
                        }
                        alt=""
                        className="w-10 h-10"
                      />
                    </div>
                    <div className=" col-span-4">
                      <h6>{e.packageName}</h6>
                      <div className="flex justify-between text-sm">
                        <span className="">Total Komentar</span>
                        <span>{money(e.commentPerDay)} / Hari</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="">Total Sebulan</span>
                        <span>{money(e.totalComment)}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className=" text-sky-500">+ Bonus Likes</span>
                        <span className="text-sky-500">
                          {money(e.totalLikes)}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className=" text-sky-500">+ Bonus Shares</span>
                        <span className="text-sky-500">
                          {money(e.totalShares)}
                        </span>
                      </div>
                    </div>
                  </>
                ),
              },
              {
                data: e?.totalPrice ? (
                  <span className="font-semibold ml-2">
                    {" "}
                    Rp. {money(e?.totalPrice).split("").slice(0, -3)}
                    <span className=" text-orange-400">
                      {money(e?.totalPrice)
                        .split("")
                        .slice(
                          Math.max(money(e?.totalPrice).split("").length - 3, 1)
                        )}
                    </span>
                  </span>
                ) : '',
              },
              {
                data: (
                  <button
                    onClick={() => {
                      nav(`/package/${e.id}?status=${mode}`);
                    }}
                    type="button"
                    className="btn"
                  >
                    Lihat Detail
                  </button>
                ),
              },
            ],
          }))}
        />
      </div>
    );
  }

  return (
    <div className="min-h-[100px]">
      {!isLoading && (
        <ul>
          {data.map((e) => (
            <li key={e.id}>{renderCard(e)}</li>
          ))}
        </ul>
      )}
      {data.length == 0 && !isLoading && <NoData />}
      <div className=" justify-center items-start flex mt-4">
        {(pagination?.totalPages ?? 0) > 0 &&
          (pagination?.next ?? 0) > page && (
            <button
              className="btn-white w-48"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              More
            </button>
          )}
      </div>
    </div>
  );
};
export default AdminOrderList;
