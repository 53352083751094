import moment from "moment";
import { useContext, useEffect, useState, type FC } from "react";
import Chart from "react-apexcharts";
import { FaChartLine } from "react-icons/fa";
import { PiChartLineDown, PiChartLineUp, PiUserBold } from "react-icons/pi";
import Moment from "react-moment";
import { adminGeneralClient } from "../../App";
import {
  GetSummaryRequest,
  GetSummaryResponseData,
  GetSummaryRevenueRequest,
  GetSummaryRevenueResponseData,
  GetSummaryUserRequest,
  GetSummaryUserResponseData,
} from "../../grpc/admin/admin_general_pb";
import AdminMid from "../../middlewares/AdminMid";
import { LoadingContext } from "../../objects/loading_context";
import {
  FIVE_MONTH,
  FIVE_YEAR,
  ONE_MONTH,
  ONE_WEEK,
  ONE_YEAR,
  ORDER_CONFIRMATION,
  THREE_MONTH,
} from "../../utils/contants";
import { getToken, money } from "../../utils/helper";
import { errorToast } from "../../utils/helper-ui";
import AdminListCommentOrder from "../../components/admin/AdminListCommentOrder";
import { GetProfileResponseData } from "../../grpc/service/general_pb";
import AdminOrderList from "../../components/admin/AdminOrderList";

interface AdminHomeProps {}

const AdminHome: FC<AdminHomeProps> = ({}) => {
  const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
  const [mounted, setMounted] = useState(false);
  const [userSummaries, setUserSummaries] = useState<
    GetSummaryUserResponseData.AsObject[]
  >([]);
  const [userSummary, setUserSummary] =
    useState<GetSummaryUserResponseData.AsObject | null>(null);
  const [revenueSummaries, setRevenueSummaries] = useState<
    GetSummaryRevenueResponseData.AsObject[]
  >([]);
  const [summary, setSummary] =
    useState<GetSummaryResponseData.AsObject | null>(null);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [durationUnit, setDurationUnit] = useState("");
  const [durationValue, setDurationValue] = useState(0);
  const [selectedTimeDuration, setSelectedTimeDuration] = useState(ONE_WEEK);
  const [selectedSummary, setSelectedSummary] = useState("user");
  const [labelSummary, setLabelSummary] = useState("Last Week");
  const [startDate, setStartDate] = useState<Date>(new Date());

  const timeRange = [
    ONE_WEEK,
    ONE_MONTH,
    THREE_MONTH,
    FIVE_MONTH,
    ONE_YEAR,
    FIVE_YEAR,
  ];

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    getSummary();
  }, [mounted, durationValue, durationUnit]);
  useEffect(() => {
    changeTime();
  }, [selectedTimeDuration]);

  // useEffect(() => {
  //     if (!mounted) return
  //     getOrder()
  // }, [page, limit, mounted]);

  const changeTime = () => {
    switch (selectedTimeDuration) {
      case ONE_WEEK:
        setDurationValue(7);
        setDurationUnit("day");
        setLabelSummary("Last Week");
        break;
      case ONE_MONTH:
        setDurationValue(5);
        setDurationUnit("week");
        setLabelSummary("Last Month");
        break;

      case THREE_MONTH:
        setDurationValue(3);
        setDurationUnit("month");
        setLabelSummary("Last 3 Months");
        break;
      case FIVE_MONTH:
        setDurationValue(5);
        setDurationUnit("month");
        setLabelSummary("Last 5 Months");
        break;
      case ONE_YEAR:
        setDurationValue(12);
        setDurationUnit("month");
        setLabelSummary("Last Year");
        break;
      case FIVE_YEAR:
        setDurationValue(5);
        setDurationUnit("year");
        setLabelSummary("Last 5 Years");
        break;
      default:
        setDurationValue(7);
        setDurationUnit("day");
        setLabelSummary("Last Week");
    }
  };

  const getSummary = async () => {
    if (durationValue == 0) return;
    try {
      setIsLoading(true);
      // console.log(durationUnit, durationValue)
      let respSummary: GetSummaryResponseData.AsObject = await new Promise(
        (resolve, reject) => {
          let req = new GetSummaryRequest();
          req.setDurationUnit(durationUnit);
          req.setDurationValue(durationValue);
          req.setStartDate(
            moment.utc(startDate).format("YYYY-MM-DDTHH:mm:ssZZ")
          );
          adminGeneralClient.getSummary(req, getToken(), (err, resp) => {
            if (err) {
              reject(err.message);
              return;
            }
            resolve(resp.getData()?.toObject()!);
          });
        }
      );

      // console.log("respSummary", respSummary)
      setSummary(respSummary);

      let respUser: GetSummaryUserResponseData.AsObject[] = await new Promise(
        (resolve, reject) => {
          let req = new GetSummaryUserRequest();
          req.setDurationUnit(durationUnit);
          req.setDurationValue(durationValue);
          adminGeneralClient.getSummaryUser(req, getToken(), (err, resp) => {
            if (err) {
              reject(err.message);
              return;
            }
            resolve(resp.getDataList().map((e) => e.toObject()));
          });
        }
      );
      setUserSummaries(respUser);
      if (respUser) {
        setUserSummary(respUser[respUser.length - 1]);
      }
      // console.log("respUser", respUser)

      let respRevenue: GetSummaryRevenueResponseData.AsObject[] =
        await new Promise((resolve, reject) => {
          let req = new GetSummaryRevenueRequest();
          req.setDurationUnit(durationUnit);
          req.setDurationValue(durationValue);
          adminGeneralClient.getSummaryRevenue(req, getToken(), (err, resp) => {
            if (err) {
              reject(err.message);
              return;
            }
            resolve(resp.getDataList().map((e) => e.toObject()));
          });
        });

      setRevenueSummaries(respRevenue);

      // console.log("respRevenue", respRevenue)
    } catch (error) {
      errorToast(`${error}`);
      console.log(`${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderXAxis = (date: string) => {
    switch (selectedTimeDuration) {
      case ONE_WEEK:
        return moment.utc(date).format("DD");
        break;
      case ONE_MONTH:
        return moment.utc(date).format("DD-MMM");
        break;

      case THREE_MONTH:
        return moment.utc(date).format("DD-MMM");
        break;
      case FIVE_MONTH:
        return moment.utc(date).format("DD-MMM");
        break;
      case ONE_YEAR:
        return moment.utc(date).format("MMM");
        break;
      case FIVE_YEAR:
        return moment.utc(date).format("YYYY");
        break;
      default:
        return moment.utc(date).format("DD");
    }
  };

  return (
    <AdminMid onLoadProfile={setProfile} noPadding>
      <div className="p-4 grid grid-cols-2 gap-4">
        <div className="bg-white rounded-xl shadow-sm hover:shadow-lg p-4 min-h-[100px] flex flex-col">
          <div
            onClick={() => {}}
            className={`border p-3 rounded-lg flex flex-col justify-center items-center ${
              selectedSummary == "user" ? "border-sky-400" : ""
            }`}
          >
            <p className="mb-2 font-semibold">Total User</p>
            <div className="flex items-center text-xs">
              <PiUserBold className="w-4 mr-2" /> {money(summary?.totalUser)}
            </div>
            <div className="flex items-center text-xs mt-2">
              {(summary?.totalUserPercentageIncrease ?? 0) < 0 ? (
                <PiChartLineDown className="w-4 text-red-400" />
              ) : (
                <PiChartLineUp className="w-4 text-green-400" />
              )}{" "}
              {money(summary?.totalUserPercentageIncrease)}% from {labelSummary}
            </div>
          </div>
          <div className=" grid gap-4 grid-cols-3 mt-4">
            <div className="flex flex-col  min-h-16 pl-3 border-l-4  border-l-green-500 justify-between">
              <span className="font-bold">ACTIVE</span>
              <span className=" font-bold text-green-500 text-lg">
                {money(userSummary?.activeUser)}
              </span>
            </div>
            <div className="flex flex-col  min-h-16 pl-3 border-l-4  border-l-orange-500 justify-between">
              <span className="font-bold">PASSIVE</span>
              <span className=" font-bold text-orange-500 text-lg">
                {money(userSummary?.passiveUser)}
              </span>
            </div>
            <div className="flex flex-col  min-h-16 pl-3 border-l-4  border-l-yellow-300 justify-between">
              <span className="font-bold">FREE</span>
              <span className=" font-bold text-yellow-300 text-lg">
                {money(userSummary?.freeUser)}
              </span>
            </div>
          </div>
          <Moment className="text-xs mt-2" format="DD MMMM YYYY">
            {userSummary?.date}
          </Moment>
          <Chart
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                id: "basic-bar",
              },
              legend: {
                show: false,
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              xaxis: {
                categories: [...userSummaries.map((e) => renderXAxis(e.date))],
              },
              yaxis: {
                show: false,
              },
              tooltip: {
                y: {
                  formatter(val, opts) {
                    return money(val);
                  },
                },
              },
            }}
            series={[
              {
                name: "Active User",
                data: [...userSummaries.map((e) => e.activeUser)],
                color: "#0c9f6e",
              },
              {
                name: "Passive User",
                data: [...userSummaries.map((e) => e.passiveUser)],
                color: "#ff5a1f",
              },
              {
                name: "Free User",
                data: [...userSummaries.map((e) => e.freeUser)],
                color: "#faca16",
              },
            ]}
            type="line"
          />
        </div>
        <div className="bg-white rounded-xl shadow-sm hover:shadow-lg p-4 min-h-[100px] flex flex-col">
          <div
            onClick={() => {}}
            className={`border p-3 rounded-lg flex flex-col justify-center items-center ${
              selectedSummary == "revenue" ? "border-sky-400" : ""
            }`}
          >
            <p className="mb-2 font-semibold">Revenue</p>
            <div className="flex items-center text-xs">
              <FaChartLine className="w-4 mr-2" /> {money(summary?.revenue)}
            </div>
            <div className="flex items-center text-xs mt-2">
              {(summary?.revenuePercentageIncrease ?? 0) < 0 ? (
                <PiChartLineDown className="w-4 text-red-400" />
              ) : (
                <PiChartLineUp className="w-4 text-green-400" />
              )}{" "}
              {money(summary?.revenuePercentageIncrease)}% from {labelSummary}
            </div>
          </div>
          <Chart
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                id: "basic-bar",
              },
              legend: {
                show: false,
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              xaxis: {
                categories: [
                  ...revenueSummaries.map((e) => renderXAxis(e.date)),
                ],
              },
              yaxis: {
                show: false,
              },
              tooltip: {
                y: {
                  formatter(val, opts) {
                    return money(val);
                  },
                },
              },
            }}
            series={[
              {
                name: "Revenue",
                data: [...revenueSummaries.map((e) => e.revenue)],
                color: "#0c9f6e",
              },
            ]}
            type="line"
          />
        </div>
      </div>
      <div className="p-4 ">
        <div className="bg-white rounded-xl shadow-sm hover:shadow-lg p-4 min-h-[100px] flex flex-col">
          <div className=" grid grid-cols-6 gap-4  mt-4">
            {timeRange.map((e) => (
              <div
                onClick={() => {
                  setSelectedTimeDuration(e);
                }}
                className={`text-center font-semibold rounded-lg p-2 ${
                  selectedTimeDuration == e && "bg-sky-400 text-white"
                } `}
                key={e}
              >
                {e}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="p-4 grid grid-cols-2 gap-4">
        <AdminListCommentOrder />
        <div className="bg-white rounded-xl shadow-sm hover:shadow-lg">
          <div className="p-4">
            <h5 className="mb-4">Konfirmasi</h5>
            <AdminOrderList mode={ORDER_CONFIRMATION} />
          </div>
        </div>
      </div>
    </AdminMid>
  );
};
export default AdminHome;
