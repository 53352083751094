import { Suspense, useEffect, useState } from "react";
import { LoginResponseData } from "../src/grpc/auth/auth_pb";
import { AppRoute } from "./routes/AppRoutes";
import "rsuite/dist/rsuite.min.css";
import { CustomProvider } from "rsuite";
import id from "./objects/id";
import { ClientUserPackageHandlerClient } from "./grpc/client/client_user_package_grpc_web_pb";
import { ClientOrderHandlerClient } from "./grpc/client/client_order_grpc_web_pb";
import { LoadingContext } from "./objects/loading_context";
import Splash from "./components/splash";
import { AuthHandlerClient } from "./grpc/auth/auth_grpc_web_pb";
import { ClientGeneralHandlerClient } from "./grpc/client/client_general_grpc_web_pb";
import { FileHandlerClient } from "./grpc/service/file_grpc_web_pb";
import { OrderContext, orderDataContext } from "./objects/order_context";
import { GeneralHandlerClient } from "./grpc/service/general_grpc_web_pb";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AdminGeneralHandlerClient } from "./grpc/admin/admin_general_grpc_web_pb";
import { AdminOrderHandlerClient } from "./grpc/admin/admin_order_grpc_web_pb";
import { AdminClientHandlerClient } from "./grpc/admin/admin_client_grpc_web_pb";
import { AdminAffiliatorHandlerClient } from "./grpc/admin/admin_affiliator_grpc_web_pb";
import { AdminCommentatorHandlerClient } from "./grpc/admin/admin_commentator_grpc_web_pb";
import { AdminUserPackageHandlerClient } from "./grpc/admin/admin_user_package_grpc_web_pb";
import { AffiliatorGeneralHandlerClient } from "./grpc/affiliator/affiliator_general_grpc_web_pb";
import { AffiliatorOrderHandlerClient } from "./grpc/affiliator/affiliator_order_grpc_web_pb";
import { AffiliatorUserPackageHandlerClient } from "./grpc/affiliator/affiliator_user_package_grpc_web_pb";
import { AffiliatorCommentatorHandlerClient } from "./grpc/affiliator/affiliator_commentator_grpc_web_pb";
import { AffiliatorClientHandlerClient } from "./grpc/affiliator/affiliator_client_grpc_web_pb";
import { CommentatorClientHandlerClient } from "./grpc/commentator/commentator_client_grpc_web_pb";
import { CommentatorAccountHandlerClient } from "./grpc/commentator/commentator_account_grpc_web_pb";
import { CommentatorGeneralHandlerClient } from "./grpc/commentator/commentator_general_grpc_web_pb";
import { CommentatorUserPackageHandlerClient } from "./grpc/commentator/commentator_user_package_grpc_web_pb";
import { SidebarContext } from "./objects/SidebarContext";
import { CommentatorOrderHandlerClient } from "./grpc/commentator/commentator_order_grpc_web_pb";
import { CommentatorWithdrawRequestHandlerClient } from "./grpc/commentator/commentator_withdraw_request_grpc_web_pb";
import { AffiliatorWithdrawRequestHandlerClient } from "./grpc/affiliator/affiliator_withdraw_request_grpc_web_pb";
import { AdminWithdrawRequestHandlerClient } from "./grpc/admin/admin_withdraw_request_grpc_web_pb";

export const clientUserPackage = new ClientUserPackageHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const clientOrder = new ClientOrderHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const clientAuth = new AuthHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const clientGeneral = new ClientGeneralHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const clientFile = new FileHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const generalClient = new GeneralHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const serviceGeneralClient = new GeneralHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const authClient = new AuthHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const adminGeneralClient = new AdminGeneralHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const adminOrderClient = new AdminOrderHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const adminClientClient = new AdminClientHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const adminAffiliatorClient = new AdminAffiliatorHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const adminCommentatorClient = new AdminCommentatorHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const adminUserPackageClient = new AdminUserPackageHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const affiliatorGeneralClient = new AffiliatorGeneralHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const affiliatorOrderClient = new AffiliatorOrderHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const affiliatorUserPackageClient =
  new AffiliatorUserPackageHandlerClient(process.env.REACT_APP_GRPC_SERVER!);
export const affiliatorCommentatorClient =
  new AffiliatorCommentatorHandlerClient(process.env.REACT_APP_GRPC_SERVER!);
export const affiliatorClientClient = new AffiliatorClientHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const commentatorClientClient = new CommentatorClientHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const commentatorAccountClient = new CommentatorAccountHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const commentatorGeneralClient = new CommentatorGeneralHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const commentatorPackageClient = new CommentatorUserPackageHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const commentatorOrderClient = new CommentatorOrderHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const fileClient = new FileHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);

export const commentatorWithdrawClient = new CommentatorWithdrawRequestHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);
export const affiliatorWithdrawClient = new AffiliatorWithdrawRequestHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
);

export const adminWithdrawClient = new AdminWithdrawRequestHandlerClient(
  process.env.REACT_APP_GRPC_SERVER!
)

function App() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [orderCtx, setOrderCtx] = useState<orderDataContext | null>(null);
  const [splash, setSplash] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 500);
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SidebarContext.Provider value={{ isOpen: isOpen, setOpen: setIsOpen }}>
        <LoadingContext.Provider
          value={{ isLoading: loading, setIsLoading: setLoading }}
        >
          <OrderContext.Provider value={{ orderCtx, setOrderCtx }}>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_AUTH_KEY!}
            >
              <CustomProvider locale={id}>
                {splash ? <Splash /> : <AppRoute />}
              </CustomProvider>
            </GoogleOAuthProvider>
          </OrderContext.Provider>
        </LoadingContext.Provider>
      </SidebarContext.Provider>
    </Suspense>
  );
}

export default App;
