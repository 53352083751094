import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useContext, useEffect, useState, type FC } from 'react';
import { HiOutlineEye } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { useToaster } from 'rsuite';
import { clientAuth, clientUserPackage, generalClient } from '../App';
import Loading from '../components/loading';
import { LoginPasswordRequest, LoginResponseData, LoginWithGoogleRequest } from '../grpc/auth/auth_pb';
import { ClientGeneralHandlerClient } from '../grpc/client/client_general_grpc_web_pb';
import { GetProfileRequest } from '../grpc/client/client_general_pb';
import { ClientUserPackageHandlerClient } from '../grpc/client/client_user_package_grpc_web_pb';
import { GetUserPackageStatusRequest } from '../grpc/client/client_user_package_pb';
import { LoadingContext } from '../objects/loading_context';
import { AFFILIATOR, COMMENTATOR, REFRESH_TOKEN, SUPER_ADMIN, TOKEN, USER_ROLE } from '../utils/contants';
import { asyncLocalStorage, getToken, setStorageProfile, setStorageUserPackage } from '../utils/helper';
import { errorToast, successToast } from '../utils/helper-ui';


interface LoginProps { }

const Login: FC<LoginProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const nav = useNavigate()
    const [mounted, setMounted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const toaster = useToaster();
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        setMounted(true)

    }, []);

    useEffect(() => {
        if (!mounted) return

    }, [mounted]);


    const setUpLocalData = async (respLogin: LoginResponseData.AsObject) => {
        if (respLogin.role != SUPER_ADMIN ) {
            errorToast("Your account is not authorized")
            return
        }
    
        await asyncLocalStorage.setItem(TOKEN, respLogin?.accessToken)
        await asyncLocalStorage.setItem(REFRESH_TOKEN, respLogin?.refreshToken)
        await asyncLocalStorage.setItem(USER_ROLE, respLogin?.role)
        let profile = await getProfile()
        await setStorageProfile(profile)
        window.location.href = "/"

        // switch (respLogin?.role) {
        //     case "user":
                
        //         let userPackage = await await getPackage()
        //         await setStorageUserPackage(userPackage)
        //         window.location.href = "/"
        //         break;
        //     case "admin":
        //         window.location.href = "/admin"
        //         break;
        //     case "commentator":
        //         window.location.href = "/commentator"
        //         break;
        //     case "affiliator":
        //         window.location.href = "/affiliator"
        //         break;

        //     default:
        //         window.location.href = "/not-found"
        //         break;
        // }
    }
    const onLogin = async () => {
        try {
            setIsLoading(true)
            let req = new LoginPasswordRequest()
            req.setInput(email)
            req.setPassword(password)
            req.setDevice("web")
            req.setFcmToken(localStorage.getItem("notification-token") ?? "")
            let respLogin: LoginResponseData.AsObject = await new Promise((resolve, reject) => {
                clientAuth.loginPassword(req, {}, async (err, resp) => {
                    if (err) {
                        reject(err.message)

                        return
                    }
                    let respLogin = resp.getData()?.toObject()
                    resolve(respLogin!)
                })
            })
            setUpLocalData(respLogin)

           

        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }


    }


    const getProfile = async () => {
        return new Promise((resolve, reject) => {
            generalClient.getProfile(new GetProfileRequest(), getToken(), (err, resp) => {
                if (err) {
                    reject(err.message)
                    return
                }
                resolve(resp.getData()?.toObject())
            })
        })

    }
    const getPackage = async () => {
        return new Promise((resolve, reject) => {
            clientUserPackage.getUserPackageStatus(new GetUserPackageStatusRequest(), getToken(), (err, resp) => {
                if (err) {
                    reject(err.message)
                    return
                }
                resolve(resp.getData()?.toObject())
            })
        })

    }

    const onSuccessGoogle = async (val: CredentialResponse) => {
        try {
            setIsLoading(true)
            let req = new LoginWithGoogleRequest()
            req.setDevice("web")
            req.setToken(val.credential!)
            req.setFcmToken(localStorage.getItem("notification-token") ?? "")

            let respLogin: LoginResponseData.AsObject = await new Promise((resolve, reject) => {
                clientAuth.loginWithGoogleWeb(req, {}, async (err, resp) => {
                    if (err) {
                        reject(err.message)
                        return
                    }

                    let respLogin = resp.getData()?.toObject()

                    resolve(respLogin!)
                })
            })
            setUpLocalData(respLogin)

        } catch (error) {
            errorToast(`${error}`)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='h-screen w-full'>
            <Loading />
            <div className='flex flex-col p-4  w-full md:max-w-[400px] m-auto'>
                <img src={"/assets/logo.svg"} alt="" className=' w-24 mx-auto mt-12 ' />
                <h3 className='text-center font-bold text-xl mb-8'><span className=' text-sky-500 mr-1 mb-4'>Grosir</span><span>Komen</span></h3>
                <h3 className='font-bold mb-0 text-sky-500' >Masuk</h3>
                <p>Silahkan masuk dengan akun yang sudah ada</p>
                <label className='font-bold mb-1 px-1 mt-4' htmlFor="">Email</label>
                <input value={email} placeholder="Alamat Email" className='mb-4 form-control' onChange={(el) => setEmail(el.currentTarget.value)} />
                <label className='font-bold mb-1 px-1' htmlFor="">Password</label>
                <div className=' relative'>
                    <input value={password} type={showPassword ? 'text' : 'password'} placeholder="Password" className='form-control mb-4' onChange={(el) => setPassword(el.currentTarget.value)} />
                    <HiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} className={`${showPassword ? 'text-gray-600' : 'text-gray-300'}  absolute top-2 right-2`} />
                </div>
                <div className=' flex justify-end text-sky-500 font-bold mb-8 cursor-pointer' onClick={() => nav('/forgot')}>
                    Lupa Kata Sandi ?
                </div>
                <button onClick={onLogin} type="button" className="btn">Masuk</button>

                <div className="flex justify-center mt-4 w-full bg-white p-2 rounded-full border">
                    <GoogleLogin
                        onSuccess={onSuccessGoogle}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />

                </div>

                




            </div>
        </div>
    );
}
export default Login;