import { useContext, useEffect, useState, type FC } from "react";
import { Nav, Sidenav } from "rsuite";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import MagicIcon from "@rsuite/icons/legacy/Magic";
import GearCircleIcon from "@rsuite/icons/legacy/GearCircle";
import Peoples from "@rsuite/icons/legacy/Peoples";
import ShoppingBag from "@rsuite/icons/legacy/ShoppingBag";
import { SidebarContext } from "../objects/SidebarContext";
import { useLocation, useNavigate } from "react-router-dom";
import UserInfoIcon from '@rsuite/icons/UserInfo';


interface SidebarNavProps {}

const SidebarNav: FC<SidebarNavProps> = ({}) => {
  const { isOpen, setOpen } = useContext(SidebarContext);
  const loc = useLocation()
  const nav = useNavigate()

  const [activeKey, setActiveKey] = useState("/");
  useEffect(() => {
    setActiveKey(loc.pathname)
  }, [loc]);
  return (
    <div
      className="h-full border-r relative"
      style={{ width: isOpen ? 240 : 60, backgroundColor: "white" }}
    >
      <div className="fixed top-0 left-0 border-r"   style={{ width: isOpen ? 240 : 60, backgroundColor: "white" }}>
        <Sidenav
          expanded={isOpen}
          className="bg-white"
        >
          <Sidenav.Header className="bg-white">
            <div className="flex items-center p-4">
              <img src={"/assets/logo.svg"} alt="" className=" w-6  mr-2 " />
              {isOpen && (
                <h3 className="font-bold text-lg">
                  <span className=" text-sky-500 mr-1 mb-4">Grosir</span>
                  <span>Komen</span>
                </h3>
              )}
            </div>
          </Sidenav.Header>
          <Sidenav.Body className="bg-white">
            <Nav activeKey={activeKey} onSelect={setActiveKey}>
              <Nav.Item eventKey="/" icon={<DashboardIcon />} onClick={() => nav(`/`)}>
                Dashboard
              </Nav.Item>
              <Nav.Item eventKey="/client" icon={<UserInfoIcon />} onClick={() => nav(`/client`)}>
                Client
              </Nav.Item>
              <Nav.Item eventKey="/package" icon={<ShoppingBag />} onClick={() => nav(`/package`)}>
                Order
              </Nav.Item>
              <Nav.Item eventKey="/affiliator" icon={<Peoples />} onClick={() => nav(`/affiliator`)}>
                Affiliator
              </Nav.Item>
              {/* <Nav.Menu
                placement="rightStart"
                eventKey="3"
                title="Advanced"
                icon={<MagicIcon />}
              >
                <Nav.Item eventKey="3-1">Geo</Nav.Item>
                <Nav.Item eventKey="3-2">Devices</Nav.Item>
                <Nav.Item eventKey="3-3">Loyalty</Nav.Item>
                <Nav.Item eventKey="3-4">Visit Depth</Nav.Item>
              </Nav.Menu>
              <Nav.Menu
                placement="rightStart"
                eventKey="4"
                title="Settings"
                icon={<GearCircleIcon />}
              >
                <Nav.Item eventKey="4-1">Applications</Nav.Item>
                <Nav.Item eventKey="4-2">Channels</Nav.Item>
                <Nav.Item eventKey="4-3">Versions</Nav.Item>
                <Nav.Menu eventKey="4-5" title="Custom Action">
                  <Nav.Item eventKey="4-5-1">Action Name</Nav.Item>
                  <Nav.Item eventKey="4-5-2">Action Params</Nav.Item>
                </Nav.Menu>
              </Nav.Menu> */}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    </div>
  );
};
export default SidebarNav;
