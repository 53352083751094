import { useContext, useEffect, useState, type FC } from "react";
import { PiPlusBold } from "react-icons/pi";
import AdminMid from "../../middlewares/AdminMid";
import { Avatar, Drawer } from "rsuite";
import { HiXMark } from "react-icons/hi2";
import { LoadingContext } from "../../objects/loading_context";
import {
  adminAffiliatorClient,
  adminClientClient,
  adminCommentatorClient,
  generalClient,
} from "../../App";
import {
  InviteTemporaryUserRequest,
  ReportOffenseRequest,
} from "../../grpc/service/general_pb";
import { getToken, initials, money } from "../../utils/helper";
import { errorToast, successToast } from "../../utils/helper-ui";
import AdminAffiliatorList from "../../components/admin/AdminAffiliatorList";
import {
  BlacklistAffiliatorRequest,
  GetAffiliatorListResponseData,
} from "../../grpc/admin/admin_affiliator_pb";
import {
  GetListClientByAffiliatorIdRequest,
  GetListClientByAffiliatorIdResponseData,
} from "../../grpc/admin/admin_client_pb";
import {
  GetListCommentatorByAffiliatorIdRequest,
  GetListCommentatorByAffiliatorIdResponseData,
} from "../../grpc/admin/admin_commentator_pb";

interface AdminAffiliatorProps {}

const AdminAffiliator: FC<AdminAffiliatorProps> = ({}) => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [mounted, setMounted] = useState(false);
  const [name, setName] = useState("");
  const [modalClientOpen, setModalClientOpen] = useState(false);
  const [modalCommentatorOpen, setModalCommentatorOpen] = useState(false);
  const [clients, setClients] = useState<
    GetListClientByAffiliatorIdResponseData.AsObject[]
  >([]);
  const [commentators, setCommentators] = useState<
    GetListCommentatorByAffiliatorIdResponseData.AsObject[]
  >([]);
  const [selectedAffiliator, setSelectedAffiliator] =
    useState<GetAffiliatorListResponseData.AsObject | null>(null);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [modalBlackListOpen, setModalBlackListOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [modalAddOpen, setModalAddOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const onClickClient = (user: GetAffiliatorListResponseData.AsObject) => {
    setIsLoading(true);
    let req = new GetListClientByAffiliatorIdRequest();
    req.setAffiliatorId(user.id);
    adminClientClient.getListClientByAffiliatorId(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setClients(resp.getDataList().map((e) => e.toObject()));
        setModalClientOpen(true);
      }
    );
  };
  const onClickCommentator = (user: GetAffiliatorListResponseData.AsObject) => {
    setIsLoading(true);
    let req = new GetListCommentatorByAffiliatorIdRequest();
    req.setAffiliatorId(user.id);
    adminCommentatorClient.getListCommentatorByAffiliatorId(
      req,
      getToken(),
      (err, resp) => {
        setIsLoading(false);
        if (err) {
          errorToast(err.message);
          return;
        }
        setCommentators(resp.getDataList().map((e) => e.toObject()));
        setModalCommentatorOpen(true);
      }
    );
  };

  const onClickWarning = (user: GetAffiliatorListResponseData.AsObject) => {
    setSelectedAffiliator(user);
    setModalWarningOpen(true);
  };

  const onClickBlackList = (user: GetAffiliatorListResponseData.AsObject) => {
    setSelectedAffiliator(user);
    setModalBlackListOpen(true);
  };

  const clearForm = () => {
    setModalWarningOpen(false);
    setModalBlackListOpen(false);
    setSelectedAffiliator(null);
    setRemarks("");
  };
  return (
    <AdminMid noPadding>
      <div className="flex justify-end bg-white">
        <div
          className=" cursor-pointer  flex flex-row bg-gray-50 p-4 rounded-full shadow-sm m-4 gap-2 hover:shadow-lg"
          onClick={() => setModalAddOpen(true)}
        >
          <PiPlusBold size={16} />
          Affiliator
        </div>
      </div>
      {mounted && (
        <AdminAffiliatorList
          onClickClient={onClickClient}
          onClickCommentator={onClickCommentator}
          onClickWarning={onClickWarning}
          onClickBlackList={onClickBlackList}
        />
      )}

      <Drawer
        className="drawer-rounded"
        size={"xs"}
        closeButton={null}
        placement={"right"}
        open={modalAddOpen}
        onClose={() => setModalAddOpen(false)}
      >
        <Drawer.Title>
          <div className="flex p-4  justify-between items-center">
            <div className="flex   items-center">
              <p> Tambah Affiliator</p>
            </div>
            <HiXMark onClick={() => setModalAddOpen(false)} />
          </div>
        </Drawer.Title>
        <Drawer.Body style={{ padding: 20 }}>
          <p className="font-semibold mb-2">Nama Lengkap</p>
          <input
            type="text"
            className="form-control"
            placeholder="Masukan Nama Lengkap ..."
            value={name}
            onChange={(el) => setName(el.target.value)}
          />
          <button
            onClick={() => {
              if (name.length == 0) return;
              setIsLoading(true);
              let req = new InviteTemporaryUserRequest();
              req.setName(name);
              generalClient.inviteTemporaryUser(
                req,
                getToken(),
                (err, resp) => {
                  if (err) {
                    setIsLoading(false);
                    errorToast(err.message);
                    return;
                  }

                  // console.log(resp.getData()?.toObject())
                  navigator.clipboard.writeText(
                    resp.getData()?.toObject().text ?? ""
                  );
                  successToast("Undangan telah di copy ke clipboard");
                  setIsLoading(false);
                  setName("");
                }
              );
            }}
            type="button"
            className="btn w-full mt-8"
          >
            Copy Invitation
          </button>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        size={"xs"}
        closeButton={null}
        placement={"right"}
        open={modalClientOpen}
        onClose={() => setModalClientOpen(false)}
      >
        <Drawer.Title>
          <div className="flex p-4  justify-between items-center">
            <div className="flex   items-center">
              <p> Klien</p>
            </div>
            <HiXMark onClick={() => setModalClientOpen(false)} />
          </div>
        </Drawer.Title>
        <Drawer.Body style={{ padding: 20 }}>
          <ul>
            {clients.map((e) => (
              <li key={e.id}>
                <div className="flex justify-between items-center mb-2 border-b pb-2 last:border-b-0">
                  <div className=" flex items-center">
                    <Avatar
                      size={"sm"}
                      circle
                      src={e.profilePictureUrl}
                      alt={e.profilePictureUrl ? e.name : initials(e.name)}
                    />
                    <p className=" font-semibold ml-2">{e.name}</p>
                  </div>
                  <div>{money(e.commentPerDay)} / Hari</div>
                </div>
              </li>
            ))}
          </ul>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        size={"xs"}
        closeButton={null}
        placement={"right"}
        open={modalCommentatorOpen}
        onClose={() => setModalCommentatorOpen(false)}
      >
        <Drawer.Title>
          <div className="flex p-4  justify-between items-center">
            <div className="flex   items-center">
              <p> Komentator</p>
            </div>
          </div>
        </Drawer.Title>
        <Drawer.Body style={{ padding: 20 }}>
          <ul>
            {commentators.map((e) => (
              <li key={e.id}>
                <div className="flex justify-between items-center mb-2 border-b pb-2 last:border-b-0">
                  <div className=" flex items-center">
                    <Avatar
                      size={"sm"}
                      circle
                      src={e.profilePictureUrl}
                      alt={e.profilePictureUrl ? e.name : initials(e.name)}
                    />
                    <p className=" font-semibold ml-2">{e.name}</p>
                  </div>
                  <div>{money(e.commentatorAccountCount)} / Slot</div>
                </div>
              </li>
            ))}
          </ul>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        size={"xs"}
        closeButton={null}
        placement={"right"}
        open={modalWarningOpen}
        onClose={() => {
          clearForm();
        }}
      >
        <Drawer.Body style={{ padding: 20 }}>
          <div className="flex flex-col h-full">
            <div className="flex flex-1 flex-col justify-center items-center">
              <img
                src="/assets/card_attention.png"
                alt=""
                className="w-12 mb-8 mt-8"
              />
              <p className="text-center mb-4 bg-orange-200 py-2 px-4 rounded-lg">
                Kartu digunakan untuk Affiliator yang melanggar
              </p>
              <textarea
                name=""
                className="form-control"
                id=""
                value={remarks}
                onChange={(el) => setRemarks(el.target.value)}
                rows={5}
                placeholder="Beri alasan ..."
              />
            </div>
            <div className="flex justify-between mt-4">
              <button className="btn-pills" onClick={() => clearForm()}>
                Batal
              </button>
              <button
                className="btn"
                onClick={() => {
                  setIsLoading(true);
                  let req = new ReportOffenseRequest();
                  req.setUserId(selectedAffiliator!.id);
                  req.setOrderId("");
                  req.setRemarks(remarks);
                  generalClient.reportOffense(req, getToken(), (err, resp) => {
                    setIsLoading(false);
                    if (err) {
                      errorToast(err.message);
                      return;
                    }
                    successToast(
                      `${selectedAffiliator?.name} berhasil diberikan kartu`
                    );
                    clearForm();
                    setMounted(false);
                    setTimeout(() => {
                      setMounted(true);
                    }, 300);
                  });
                }}
              >
                Beri Kartu
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <Drawer
        className="drawer-rounded"
        size={"xs"}
        closeButton={null}
        placement={"right"}
        open={modalBlackListOpen}
        onClose={() => clearForm()}
      >
        <Drawer.Body style={{ padding: 20 }}>
          <div className="flex flex-col h-full">
            <div className="flex flex-1 flex-col justify-center items-center">
              <img src="/assets/alert.png" alt="" className="w-12 mb-8 mt-8" />
              <p className="text-center mb-4  font-semibold">
                Affiliator yang di black list:
              </p>
              <ul>
                <li>TIDAK DAPAT menerima projek baru</li>
                <li>WAJIB menyelesaikan projek yang sedang berjalan</li>
                <li>BERHAK menarik fee yang masih ada</li>
              </ul>
            </div>
            <div className="flex justify-between mt-4">
              <button className="btn-pills" onClick={() => clearForm()}>
                Batal
              </button>
              <button
                className="btn-orange"
                onClick={() => {
                  setIsLoading(true);
                  let req = new BlacklistAffiliatorRequest();
                  req.setAffiliatorId(selectedAffiliator!.id);
                  adminAffiliatorClient.blacklistAffiliator(
                    req,
                    getToken(),
                    (err, resp) => {
                      setIsLoading(false);
                      if (err) {
                        errorToast(err.message);
                        return;
                      }
                      successToast(
                        `${selectedAffiliator?.name} berhasil diblacklist`
                      );
                      clearForm();
                      setMounted(false);
                      setTimeout(() => {
                        setMounted(true);
                      }, 300);
                    }
                  );
                }}
              >
                Black List
              </button>
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </AdminMid>
  );
};
export default AdminAffiliator;
