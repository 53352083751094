import { useContext, useState, type FC } from 'react';
import AdminMid from '../../middlewares/AdminMid';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { ACTIVE, FREE, MARKETER, PASSIVE } from '../../utils/contants';
import AdminClientList from '../../components/admin/AdminClientList';
import { GetClientListResponseData, GetClientUserPackageHistoryRequest, GetClientUserPackageHistoryResponseData } from '../../grpc/admin/admin_client_pb';
import { Drawer } from 'rsuite';
import { adminClientClient } from '../../App';
import { getToken, money, randomStr } from '../../utils/helper';
import { errorToast } from '../../utils/helper-ui';
import { RiHistoryLine } from 'react-icons/ri';
import { HiXMark } from 'react-icons/hi2';
import { LoadingContext } from '../../objects/loading_context';
import Moment from 'react-moment';

interface AdminClientProps { }

const AdminClient: FC<AdminClientProps> = ({ }) => {
    const { isLoading, setIsLoading } = useContext(LoadingContext);

    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [clientModeActive, setClientModeActive] = useState(ACTIVE);
    const [modalHistoryOpen, setModalHistoryOpen] = useState(false);
    const [histories, setHistories] = useState<GetClientUserPackageHistoryResponseData.AsObject[]>([]);

    const onClickHistory = (e: GetClientListResponseData.AsObject) => {
        setIsLoading(true)
        let req = new GetClientUserPackageHistoryRequest()
        req.setUserId(e.id)
        adminClientClient.getClientUserPackageHistory(req, getToken(), (err, resp) => {
            if (err) {
                setIsLoading(false)
                errorToast(err.message)
                return
            }

            setHistories(resp.getDataList().map(e => e.toObject()))
            setModalHistoryOpen(true)
            setIsLoading(false)
        })
    }
    return (
        <AdminMid header={<div className='w-full px-4 py-2 justify-center items-center flex pt-6 bg-white'>
            <h6 className='text-center'>Client</h6>

        </div>} onLoadProfile={setProfile} noPadding>
            <div className='bg-white'>
                <div className="tabmenu">
                    <div onClick={() => setClientModeActive(ACTIVE)} className={`${clientModeActive == ACTIVE && 'active'} cursor-pointer`}>Aktif</div>
                    <div onClick={() => setClientModeActive(PASSIVE)} className={`${clientModeActive == PASSIVE && 'active'} cursor-pointer`} >Pasif</div>
                    <div onClick={() => setClientModeActive(FREE)} className={`${clientModeActive == FREE && 'active'} cursor-pointer`} >Gratis</div>
                    <div onClick={() => setClientModeActive(MARKETER)} className={`${clientModeActive == MARKETER && 'active'} cursor-pointer`} >Marketer</div>
                </div>
                {clientModeActive == ACTIVE && <AdminClientList mode={ACTIVE} onClickHistory={onClickHistory} />}
                {clientModeActive == PASSIVE && <AdminClientList mode={PASSIVE} onClickHistory={onClickHistory} />}
                {clientModeActive == FREE && <AdminClientList mode={FREE} onClickHistory={onClickHistory} />}
                {clientModeActive == MARKETER && <AdminClientList mode={MARKETER} onClickHistory={onClickHistory} />}
            </div>
            <Drawer className='drawer-rounded' size={"md"} closeButton={null} placement={"right"} open={modalHistoryOpen} onClose={() => setModalHistoryOpen(false)}>
                <Drawer.Title>
                    <div className='flex p-4  justify-between items-center'>
                        <div className='flex   items-center'>
                            <RiHistoryLine className='mr-2' />
                            <p> History Pesanan</p>
                        </div>
                        <HiXMark onClick={() => setModalHistoryOpen(false)} />
                    </div>
                </Drawer.Title>
                <Drawer.Body style={{ padding: 20 }}>
                    <ul>
                        {histories.map(e => <li key={randomStr(6)}>
                            <div className='flex justify-between items-center mb-2 border-b pb-2 last:border-b-0'>
                                <div>
                                    <p className=' font-semibold'>{e.packageName}</p>
                                    <Moment format='DD MMMM YYYY'>{e.createdAt}</Moment>
                                </div>
                                <div>
                                    Rp. {money(e.totalPrice)}
                                </div>
                            </div>
                        </li>)}


                    </ul>
                </Drawer.Body>
            </Drawer>
        </AdminMid>
    );
}
export default AdminClient;