import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';
import { asyncLocalStorage } from '../utils/helper';
import { REFRESH_TOKEN, TOKEN } from '../utils/contants';

interface NotFoundProps { }

const NotFound: FC<NotFoundProps> = ({ }) => {
    const nav = useNavigate()
    return (
        <div className='flex h-screen bg-white flex-col justify-center items-center transition-opacity ease-in duration-700 opacity-100 m-auto' >
            <img src="/assets/404.gif" alt="" />
            <Button className='w-60' onClick={() => nav(-1)}>Kembali</Button>
            <Button appearance='primary' className='w-60  mt-4' color='red' onClick={async() => {
                 await asyncLocalStorage.removeItem(TOKEN)
                 await asyncLocalStorage.removeItem(REFRESH_TOKEN)
                 window.location.href = "/login"
            }}>Logout</Button>
        </div>
    );
}
export default NotFound;